// src/pages/Publications/Publications.js
import React, { useEffect, useState } from 'react';
import '../../App.css';
import '../../components/Publications.css';
import Modal from '../../components/Modal';
import { Link } from 'react-router-dom';
import Footer from '../Footer';

// ------------------ 核心函数：删除 Citation 中的 Title ------------------
function removeTitleFromCitation(title, citation) {
  // 如果有空值，直接返回 Citation
  if (!title || !citation) return citation || '';

  // 1. 为了安全地放进正则，先转义 title 中可能的正则特殊字符
  const safeTitle = title.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // 2. 构造一个正则：可选前后引号，和可选空格，然后匹配 title，再可选引号和空格
  //    - 'gi'：全局、忽略大小写匹配，如果你想区分大小写，可以去掉 'i'
  //    - 示例匹配： "A Metric Inverse..." 、 “A Metric Inverse...”、A Metric Inverse...（无引号）
  const pattern = new RegExp(`["“”]?\\s*${safeTitle}\\s*["“”]?`, 'gi');

  // 3. 用这个正则全局替换掉匹配到的标题片段
  let newCitation = citation.replace(pattern, '');

  // 4. 可能会留下多余的标点和空格，比如 ", ," 或多余空格等
  //    - 移除连续的逗号+空格
  newCitation = newCitation.replace(/,\s*,/g, ',');
  //    - 把多余空格压缩成单个空格
  newCitation = newCitation.replace(/\s{2,}/g, ' ');
  //    - 去掉开头或结尾多余的逗号、空格
  newCitation = newCitation.replace(/^[,\s]+|[,\s]+$/g, '');

  // 5. 最后再 trim 一下，返回更干净的结果
  return newCitation.trim();
}

// ------------------ 主组件：Publications ------------------
export default function Publications() {
  const [publications, setPublications] = useState({});
  const [selectedBibTex, setSelectedBibTex] = useState(null);

  // 你项目中自定义的年份区间
  const startYear = 2005;
  const endYear = 2025;

  useEffect(() => {
    // 按年度获取 publications
    const fetchPublications = async (year) => {
      try {
        const response = await fetch(`https://idealabs.cs.byu.edu/api/publications/${year}`);
        const data = await response.json();
        // 把每年的数据存进 state 对象里
        setPublications((prev) => ({ ...prev, [year]: data }));
      } catch (error) {
        console.error(`Failed to fetch publications for year ${year}:`, error);
      }
    };

    // 从 2025 到 2005 依次调用
    for (let year = endYear; year >= startYear; year--) {
      fetchPublications(year);
    }
  }, [startYear, endYear]);

  const handleBibTexClick = (bibtex) => {
    setSelectedBibTex(bibtex);
  };

  const handleCloseModal = () => {
    setSelectedBibTex(null);
  };

  return (
    <>
      <div className="news-header-container">
        <img src='/images/newsheader2.jpg' alt='News Header' className='news-header' />
        <h1 className="news-title">Publications</h1>
      </div>

      <div className="publications-container">
        <Link to="/allpresentations" className="all-presentation-link">Presentations</Link>

        {Object.keys(publications)
          .sort((a, b) => b - a)
          .filter((year) => publications[year].length > 0)
          .map((year) => (
            <div key={year} className="year-section">
              <h2>{year}</h2>
              <ul className="publications-list">
                {publications[year].map((pub, index) => {
                  // 在这里对 pub.Citation 进行处理
                  const processedCitation = removeTitleFromCitation(pub.Title, pub.Citation);

                  return (
                    <li key={index} className="publication-item">
                      <h3 className="publication-title">{pub.Title}</h3>
                      <span className="publication-citation">
                        {processedCitation}
                      </span>
                      <div className="links">
                        {pub.Pdf && (
                          <a
                            href={pub.Pdf}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="pdf-link"
                          >
                            PDF
                          </a>
                        )}
                        {pub.ExtLink && (
                          <a
                            href={pub.ExtLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="external-link"
                          >
                            External Link
                          </a>
                        )}
                        {pub.Presentation && (
                          <a
                            href={pub.Presentation}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="presentation-link"
                          >
                            Presentation
                          </a>
                        )}
                        {pub.Bibtex && (
                          <button
                            onClick={() => handleBibTexClick(pub.Bibtex)}
                            className="biblink-button"
                          >
                            BibTex
                          </button>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        
        <Modal show={!!selectedBibTex} onClose={handleCloseModal}>
          <pre>{selectedBibTex}</pre>
        </Modal>
      </div>

      <Footer />
    </>
  );
}

