import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Ensure you import Link from react-router-dom
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    return (
        <>
            <nav id="navbar" className="navbar">
                <div className="navbar-container">
                    {/* BYU Logo Link */}
                    <Link to="https://www.byu.edu" className="navbar-logo" target="_blank" rel="noopener noreferrer">
                        <img src="/images/byu-logo.png" alt="Navbar Logo" />
                    </Link>

                    {/* Idea Labs Logo Link */}
                    <Link to="/" className="navbar-logo2" onClick={closeMobileMenu}>
                        <img src="/images/Idea-Labs-White.svg" alt="Idea Labs Logo" />
                    </Link>

                    {/* Mobile Menu Icon */}
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>

                    {/* Nav Menu */}
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/publications' className='nav-links' onClick={closeMobileMenu}>
                                Publications
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/people' className='nav-links' onClick={closeMobileMenu}>
                                People
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/allprojects' className='nav-links' onClick={closeMobileMenu}>
                                Projects
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
