import React, { useEffect, useState } from 'react';
import '../../App.css';
import '../../components/Projects.css';
import Footer from '../Footer';
import '../../components/Lab.css'

export default function Projects() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch('https://idealabs.cs.byu.edu/api/hssprojects')
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(err => console.error('Error fetching projects:', err));
    }, []);

    return (
        <div>
            <div className="lab-container">
            <div className="lab-content">
                <img src="images/People.jpg" alt="Lab Image1" className="lab-image"/>
                <div className="lab-description">
                <h1 className="lab-title">Human Systems and Stories Lab (HSS)</h1>
                    <p>
                    This lab explores the way information is processed by these systems and seeks to define better modes of interaction to achieve various purposes. By focusing on decision processes in these inherently multi-agent systems, a number of abstract concepts such as competition, cooperation, persuasion, and deception can be rigorously analyzed.
                    </p>
                </div>
            </div>
        </div>
        <div className="projects-container">
                {projects.map((project) => (
                    <div key={project.ProjID} className="project-item">
                        <h2>{project.Title}</h2>
                        <img src={project.Image} alt={project.Title} className="project-image" />
                        <p>{project.Bio}</p>
                        {project.Publications && project.Publications.length > 0 && (
                            <div className="publication-details-2">
                                <ul className="publication-list-2">
                                    {project.Publications.map((publication, index) => (
                                        <li key={index} className="publication-item-2">
                                            <h3>{publication.PublicationTitle}</h3>
                                            <div className="links">
                                                {publication.Pdf && (
                                                    <a href={publication.Pdf} target="_blank" rel="noopener noreferrer" className="pdf-link-2">PDF</a>
                                                )}
                                                {publication.ExtLink && (
                                                    <a href={publication.ExtLink} target="_blank" rel="noopener noreferrer" className="external-link-2">External Link</a>
                                                )}
                                                {publication.Presentation && (
                                                    <a href={publication.Presentation} target="_blank" rel="noopener noreferrer" className="presentation-link-2">Presentation</a>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {project.Status === 'Active' && (
                            <div className="project-status-label">Active</div>
                        )}
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
}
