import React from 'react'
import '../../App.css'
import BodySection from '../BodySection'
import Cards from '../Cards'
import Footer from '../Footer'

function Home () {
    return(
        <>
            <BodySection />
            <Cards />
            <Footer />
        </>
    )
}

export default Home;