import React from 'react';
import { useLocation } from 'react-router-dom';
import '../components/NewsDetails.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

export default function NewsDetails() {
    const location = useLocation();
    const { news } = location.state;

    return (
        <div className="news-page">
            <div className="news-header-container">
                <img src='/images/newsheader2.jpg' alt='News Header' className='news-header' />
                <h1 className="news-title">News</h1>
            </div>
            <div className="news-content">
                <div className="news-items">
                    <div className="news-item-details">
                        <img src={news.Image} alt={news.Headline} className="news-details-image" />
                        <div className="news-info">
                            <h1 className="news-details-title">{news.Headline}</h1>
                            <p className="news-details-date">{news.formattedDate}</p>
                            <p className="news-details-info">
                                {news.Info.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </p>
                            <Link to="/News" className="back-button">Back</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
