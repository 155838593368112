import React, { useEffect, useState } from 'react';
import '../../App.css';
import '../../components/Projects.css';
import Footer from '../Footer';
import '../../components/Lab.css'

export default function Projects() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch('https://idealabs.cs.byu.edu/api/cefsprojects')
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(err => console.error('Error fetching projects:', err));
    }, []);

    return (
        <div>
            <div className="lab-container">
            <div className="lab-content">
                <img src="images/Economics.jpg" alt="Lab Image1" className="lab-image"/>
                <div className="lab-description">
                <h1 className="lab-title">Computational Economics and Financial Systems Lab (CEFS)</h1>
                    <p>
                    The CEFS develops methods for using accessible data to compute operational policies, such as pricing, promotion, inventory management, portfolio optimization, etc. These policies are then tested on novel learning platforms developed at IDeA Labs, such as our Retail Laboratory and Tour de France virtual fund management system.
                    </p>
                </div>
            </div>
        </div>
        <div className="projects-container">
                {projects.map((project) => (
                    <div key={project.ProjID} className="project-item">
                        <h2>{project.Title}</h2>
                        <img src={project.Image} alt={project.Title} className="project-image" />
                        <p>{project.Bio}</p>
                        {project.Publications && project.Publications.length > 0 && (
                            <div className="publication-details-2">
                                <ul className="publication-list-2">
                                    {project.Publications.map((publication, index) => (
                                        <li key={index} className="publication-item-2">
                                            <h3>{publication.PublicationTitle}</h3>
                                            <div className="links">
                                                {publication.Pdf && (
                                                    <a href={publication.Pdf} target="_blank" rel="noopener noreferrer" className="pdf-link-2">PDF</a>
                                                )}
                                                {publication.ExtLink && (
                                                    <a href={publication.ExtLink} target="_blank" rel="noopener noreferrer" className="external-link-2">External Link</a>
                                                )}
                                                {publication.Presentation && (
                                                    <a href={publication.Presentation} target="_blank" rel="noopener noreferrer" className="presentation-link-2">Presentation</a>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {project.Status === 'Active' && (
                            <div className="project-status-label">Active</div>
                        )}
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
}
