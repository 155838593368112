import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1 className='main-title'>Welcome to BYU's Information and Decision Algorithms Laboratories (IDeA Labs)!
         IDeA Labs is a network of laboratories operated by a single, interdisciplinary research group. 
         This group uses systems, control theory, and related tools to analyze and design a variety of 
         complex systems and information processing techniques.</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/byucampus.jpg'
              text='News at the IDeA Labs'
              path='/news'
            />
          </ul>
          <ul className='cards__items'>
          <CardItem
              src='images/Biology.jpg'
              text='Computational Biology and Environmental Systems Lab (CBES)'
              path='/biolab'
            />
            <CardItem
              src='images/Economics.jpg'
              text='Computational Economics and Financial Systems Lab (CEFS)'
              path='/econlab'
            />
            <CardItem
              src='images/Operations.jpg'
              text='Operations Research and Engineered Systems Lab (ORES)'
              path='/operationslab'
            />
            <CardItem
              src='images/People.jpg'
              text='Human Systems and Stories Lab (HSS)'
              path='/humanlab'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;