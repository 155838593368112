import React from 'react';
import { Link } from 'react-router-dom';
import './BodySection.css';
import '../App.css';

function BodySection() {
  return (
    <div className='hero-container'>
      <h1>IDeA Labs</h1>
      <p>Information & Decision Algorithms Laboratories</p>
      <div className='hero-btns'>
        <Link to='/publications' className='btns btn--outline btn--large'>
          Publications
        </Link>
        <Link to='/people' className='btns btn--primary btn--large'>
          People
        </Link>
      </div>
    </div>
  );
}

export default BodySection;
