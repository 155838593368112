import './App.css';
import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Home from './components/pages/Home';
import Publications from './components/pages/Publications';
import People from './components/pages/People';
import PersonDetails from './components/PersonDetails'; // Component for individual details
import Alumni from './components/pages/Alumni';
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop
import Presentation from './components/pages/Presentations'
import BioLab from './components/pages/BioLab';
import HumanLab from './components/pages/HumanLab';
import OperationsLab from './components/pages/OperationsLab';
import EconLab from './components/pages/EconLab';
import Projects from './components/pages/Projects';
import ActiveProjects from './components/pages/ActiveProjects'
import News from './components/pages/News'
import NewsDetails from './components/NewsDetails';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/publications' element={<Publications />} />
          <Route path='/people' element={<People />} />
          <Route path='/news' element={<News />} />
          <Route path="/PersonDetails/:People_id" element={<PersonDetails />} />
          <Route path='/alumni' element={<Alumni />} />
          <Route path='/allpresentations' element={<Presentation />} />
          <Route path='/biolab' element={<BioLab />} />
          <Route path='/humanlab' element={<HumanLab />} />
          <Route path='/operationslab' element={<OperationsLab />} />
          <Route path='/econlab' element={<EconLab />} />
          <Route path='/allprojects' element={<Projects />} />
          <Route path='/activeprojects' element={<ActiveProjects />} />
          <Route path="/NewsDetails/:NewsID" element={<NewsDetails />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
