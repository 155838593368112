import React, { useEffect, useState } from 'react';
import '../../App.css'; // Assuming this is the correct path to your App.css
import '../../components/Publications.css'; // Assuming this is the correct path to your Publications.css
import Footer from '../Footer';

const Presentations = () => {
  const [presentations, setPresentations] = useState([]);

  useEffect(() => {
    fetch('https://idealabs.cs.byu.edu/api/allpresentations')
      .then(response => response.json())
      .then(data => {
        setPresentations(data);
      })
      .catch(error => console.error('Error fetching presentations:', error));
  }, []);

  return (
    <>
    <div className="news-header-container">
        <img src='/images/newsheader2.jpg' alt='News Header' className='news-header' />
        <h1 className="news-title">Presentations</h1>
      </div>
    <div className="publications-container">
      <br></br>
      <ul className="publications-list">
        {presentations.map((pub, index) => (
          <li key={index} className="publication-item">
            <h3 className="publication-title">{pub.Title}</h3>
            <span className="publication-citation">{pub.Citation}</span>
            <div className="links">
              {pub.Presentation && (
                <a href={pub.Presentation} target="_blank" rel="noopener noreferrer" className="presentation-link">Presentation</a>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
    <Footer />
    </>
  );
};

export default Presentations;
