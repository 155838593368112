import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../../components/People.css';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

function truncateText(text, limit) {
    const words = text.split(' '); // Split the text into words
    if (words.length > limit) {
        return words.slice(0, limit).join(' ') + '...'; // Join the first 'limit' words and add '...'
    }
    return text;
}

export default function People() {
    const [directors, setDirectors] = useState([]);
    const [gradStudents, setGradStudents] = useState([]);
    const [undergradStudents, setUndergradStudents] = useState([]);

    useEffect(() => {
        // Fetch directors
        fetch('https://idealabs.cs.byu.edu/api/directors')
            .then(response => response.json())
            .then(data => {
                setDirectors(data);
            })
            .catch(error => console.error('Error fetching directors:', error));

        // Fetch graduate students
        fetch('https://idealabs.cs.byu.edu/api/gradstudent')
            .then(response => response.json())
            .then(data => {
                setGradStudents(data);
            })
            .catch(error => console.error('Error fetching graduate students:', error));

        // Fetch undergraduate students
        fetch('https://idealabs.cs.byu.edu/api/undergradstudent')
            .then(response => response.json())
            .then(data => {
                setUndergradStudents(data);
            })
            .catch(error => console.error('Error fetching undergraduate students:', error));
    }, []);

    return (
        <div>
            <div className="news-header-container">
                <img src='/images/newsheader2.jpg' alt='News Header' className='news-header' />
                <h1 className="news-title">People</h1>
            </div>
            <h2>Advisors</h2>
            <div className="people-container">
                {directors.map((person, index) => (
                    <Link to={`/PersonDetails/${person.People_id}`} key={index} className="student-card">
                        <img src={person.ImageURL} alt={`${person.FName} ${person.LName}`} className="student-image" />
                        <h1>{person.FName} {person.LName}</h1>
                        <div className="student-info">
                            <p>{truncateText(person.Bio, 60)}</p>
                        </div>
                    </Link>
                ))}
            </div>
            <h2>Graduate Students</h2>
            <div className="people-container-2">
                {gradStudents.map((person, index) => (
                    <Link to={`/PersonDetails/${person.People_id}`} key={index} className="student-card-2">
                        <img src={person.ImageURL} alt={`${person.FName} ${person.LName}`} className="student-image-2" />
                        <h1>{person.FName} {person.LName}</h1>
                        <div className="student-info-2">
                            <p>{truncateText(person.Bio, 35)}</p>
                        </div>
                    </Link>
                ))}
            </div>
            <h2>Undergraduate Students</h2>
            <div className="people-container-2">
                {undergradStudents.map((person, index) => (
                    <Link to={`/PersonDetails/${person.People_id}`} key={index} className="student-card-2">
                        <img src={person.ImageURL} alt={`${person.FName} ${person.LName}`} className="student-image-2" />
                        <h1>{person.FName} {person.LName}</h1>
                        <div className="student-info-2">
                            <p>{truncateText(person.Bio, 35)}</p>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="button-container">
                <Link to="/" className="btn btn--primary-1 btn--large-1">Home</Link>
                <Link to="/alumni" className="btn btn--primary-1 btn--large-1">ALUMNI</Link>
            </div>
            <Footer />
        </div>
    );
}
