import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../components/News.css';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

function truncateText(text, limit) {
    const words = text.split(' '); // Split the text into words
    if (words.length > limit) {
        return words.slice(0, limit).join(' ') + '...'; // Join the first 'limit' words and add '...'
    }
    return text;
}

export default function News() {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20); // Set the number of items per page
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://idealabs.cs.byu.edu/api/news')
            .then(response => response.json())
            .then(data => {
                const formattedData = data.map(item => ({
                    ...item,
                    formattedDate: new Date(item.Date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
                }));
                setNews(formattedData);
            })
            .catch(err => console.error('Error fetching news:', err));
    }, []);

    // Scroll to Navbar when page changes
    const scrollToNavbar = () => {
        const navbarElement = document.getElementById('navbar');
        if (navbarElement) {
            navbarElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    useEffect(() => {
        scrollToNavbar(); // Scroll to navbar on page change
    }, [currentPage]);

    const handleView = (newsItem) => {
        navigate(`/NewsDetails/${newsItem.NewsID}`, { state: { news: newsItem } });
    };

    // Get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentNews = news.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        scrollToNavbar();
    };

    const nextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
        scrollToNavbar();
    };

    return (
        <div className="news-page">
            <div className="news-header-container">
                <img src='/images/newsheader2.jpg' alt='News Header' className='news-header' />
                <h1 className="news-title">News</h1>
            </div>
            <div className='LinkedIn-Content'>
                <iframe className='LinkedIn-Post' src="https://www.linkedin.com/embed/feed/update/urn:li:share:7183603016606908417" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                <iframe className='LinkedIn-Post' src="https://www.linkedin.com/embed/feed/update/urn:li:share:7203600813070438400" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                <iframe className='LinkedIn-Post' src="https://www.linkedin.com/embed/feed/update/urn:li:share:7167414120533704704" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                <Link className='See-More-Button' to='https://www.linkedin.com/company/idealabsbyu/'><span style={{ display: 'block', textAlign: 'center', fontSize: '35px' }}>&rarr;</span></Link>
            </div>
            <div className="news-content">
                <div className="news-items">
                    {currentNews.map((newsItem, index) => (
                        <div className="news-item" key={index}>
                            <img src={newsItem.Image} alt={newsItem.Headline} className="news-image" />
                            <div className="news-info">
                                <h2 className="news-heading">{newsItem.Headline}</h2>
                                <p className="news-date">{newsItem.formattedDate}</p>
                                <p className='news-information'>
                                    {truncateText(newsItem.Info, 100).split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </p>
                                <button className="view-button" onClick={() => handleView(newsItem)}>VIEW</button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="pagination">
                    {Array.from({ length: Math.ceil(news.length / itemsPerPage) }, (_, i) => (
                        <button key={i + 1} onClick={() => paginate(i + 1)} className={`page-item-number ${currentPage === i + 1 ? 'active' : ''}`}>
                            {i + 1}
                        </button>
                    ))}
                    <button
                        onClick={nextPage}
                        className="next-page-button"
                        disabled={currentPage >= Math.ceil(news.length / itemsPerPage)}
                    >
                        Next Page
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
}
