import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaLinkedinIn } from 'react-icons/fa';
import './PersonDetails.css';
import { Link } from 'react-router-dom';

function PersonDetails() {
  const { People_id } = useParams();
  const [personDetails, setPersonDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://idealabs.cs.byu.edu/api/person/${People_id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setPersonDetails(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching person details:', error);
        setError(error.toString());
        setLoading(false);
      });
  }, [People_id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!personDetails) return <div>No data found for this person.</div>;

  const hasContactInfo = personDetails.Email || personDetails.LinkedInURL;
  const hasPublications = personDetails.Publications && personDetails.Publications.length > 0;

  return (
    <div className="main-container">
      <div className="person-details-container">
        <h1 className="person-name">{personDetails.FName} {personDetails.LName}</h1>
        <br></br>
        <div className="bio-image-container">
          <img src={personDetails.ImageURL} alt={`${personDetails.FName} ${personDetails.LName}`} />
          <div className="bio-container">
            <p>{personDetails.Bio}</p>
          </div>
        </div>
      </div>
      {hasContactInfo && (
        <div className="additional-info-container">
          <h1>Contact</h1>
          {personDetails.LinkedInURL && (
            <p>
              <a href={personDetails.LinkedInURL} target="_blank" rel="noopener noreferrer" className='linkedin-icon'>
                <FaLinkedinIn />
              </a>
            </p>
          )}
          {personDetails.Email && <p>Email: {personDetails.Email}</p>}
          <div className="button-container">
            <Link to="/people" className="btn btn--primary-1 btn--large-1">People</Link>
          </div>
        </div>
      )}
      {hasPublications && (
        <div className={`additional-info-container-2 ${!hasContactInfo ? 'move-up' : ''}`}>
          <h1>Publications</h1>
          <br></br>
          {personDetails.Publications.map((publication, index) => (
            <div key={index}>
              {publication.Title && <p>{publication.Title}</p>}
              <div className="links">
                {publication.Pdf && (
                  <a href={publication.Pdf} target="_blank" rel="noopener noreferrer" className="pdf-link-1">PDF</a>
                )}
                {publication.ExtLink && (
                  <a href={publication.ExtLink} target="_blank" rel="noopener noreferrer" className="external-link-1">External Link</a>
                )}
                {publication.Presentation && (
                  <a href={publication.Presentation} target="_blank" rel="noopener noreferrer" className="presentation-link-1">Presentation</a>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PersonDetails;
