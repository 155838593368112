import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../../components/People.css';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

function truncateText(text, limit) {
    const words = text.split(' '); // Split the text into words
    if (words.length > limit) {
        return words.slice(0, limit).join(' ') + '...'; // Join the first 'limit' words and add '...'
    }
    return text;
}

export default function People() {
    const [alumni, setalumni] = useState([]);

    useEffect(() => {
        // Fetch graduate students
        fetch('https://idealabs.cs.byu.edu/api/alumni')
            .then(response => response.json())
            .then(data => {
                setalumni(data);
            })
            .catch(error => console.error('Error fetching graduate students:', error));


    }, []);

    return (
        <div>
            <h2>Alumni</h2>
            <div className="people-container-2">
                {alumni.map((person, index) => (
                    <Link to={`/PersonDetails/${person.People_id}`} key={index} className="student-card-2">
                        <img src={person.ImageURL} alt={`${person.FName} ${person.LName}`} className="student-image-2" />
                        <h1>{person.FName} {person.LName}</h1>
                        <div className="student-info-2">
                            <p>{truncateText(person.Bio, 35)}</p>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="button-container">
                <Link to="/people" className="btn btn--primary-1 btn--large-1">People</Link>
            </div>
            <Footer />
        </div>
    );
}
