// src/components/Modal.js
import React from 'react';
import './Modal.css';

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button onClick={onClose} className="close-button">X</button>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
