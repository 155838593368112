import React, { useEffect, useState } from 'react';
import '../../App.css';
import '../../components/Projects.css';
import Footer from '../Footer';
import '../../components/Lab.css'

export default function Projects() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch('https://idealabs.cs.byu.edu/api/oresprojects')
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(err => console.error('Error fetching projects:', err));
    }, []);

    return (
        <div>
            <div className="lab-container">
            <div className="lab-content">
                <img src="images/Operations.jpg" alt="Lab Image1" className="lab-image"/>
                <div className="lab-description">
                <h1 className="lab-title">Operations Research and Engineered Systems Lab (ORES)</h1>
                    <p>
                    The ORES lab investigates computational requirements and algorithm development related to the design, control, and optimization of such systems. Working with the CEFS Lab, supply chains, inventory control, logistics, and distribution systems, etc. are also relevant to the ORES Lab’s focus. Working with the CBES Lab, drug, molecular, and genetic design are also applicable, while working with the PSHS Lab, health services, urban planning, emergency response, and human security systems are fitting targets for our analysis.
                    </p>
                </div>
            </div>
        </div>
            <div className="projects-container">
                {projects.map((project) => (
                    <div key={project.ProjID} className="project-item">
                        <h2>{project.Title}</h2>
                        <img src={project.Image} alt={project.Title} className="project-image" />
                        <p>{project.Bio}</p>
                        {project.Publications && project.Publications.length > 0 && (
                            <div className="publication-details-2">
                                <ul className="publication-list-2">
                                    {project.Publications.map((publication, index) => (
                                        <li key={index} className="publication-item-2">
                                            <h3>{publication.PublicationTitle}</h3>
                                            <div className="links">
                                                {publication.Pdf && (
                                                    <a href={publication.Pdf} target="_blank" rel="noopener noreferrer" className="pdf-link-2">PDF</a>
                                                )}
                                                {publication.ExtLink && (
                                                    <a href={publication.ExtLink} target="_blank" rel="noopener noreferrer" className="external-link-2">External Link</a>
                                                )}
                                                {publication.Presentation && (
                                                    <a href={publication.Presentation} target="_blank" rel="noopener noreferrer" className="presentation-link-2">Presentation</a>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
}


