import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const navbarElement = document.getElementById('navbar'); // Assuming your Navbar has an ID of 'navbar'
        
        if (navbarElement) {
            navbarElement.scrollIntoView({
                block: 'start'
            });
        } else {
            // Fallback to scrolling to the top if navbar isn't found
            const scrollElement = document.documentElement || document.body;
            scrollElement.scrollTo({
                top: 0
            });
        }
        
    }, [pathname]);

    return null;
};

export default ScrollToTop;
