import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-items'>
          <h2>Resources</h2>
          <Link to='https://www.byu.edu'>BYU</Link>
          <Link to='https://www.linkedin.com/company/idealabsbyu/posts/?feedView=all'>Connect on LinkedIn</Link>
          <Link to='https://math.byu.edu'>College of Physical and Mathematical Sciences</Link>
          <Link to='https://cs.byu.edu'>Computer Science Department</Link>
        </div>
        <div className='footer-main-image'>
          <img src="/images/Idea-Labs-Blue.svg" alt="Idea Labs Logo" />
        </div>
        <div className='contact-link-items'>
          <h2>Contact Us</h2>
          <p>BYU IDeA Labs</p>
          <p>323 TMCB</p>
          <p>Provo, Utah 84602</p>
          <p>(801) 422-6463</p>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <small className='website-rights'>Copyright © 2024, All Rights Reserved</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
