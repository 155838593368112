import React, { useEffect, useState } from 'react';
import '../../App.css';
import '../../components/Projects.css';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

export default function Projects() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch('https://idealabs.cs.byu.edu/api/activeprojects')
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(err => console.error('Error fetching projects:', err));
    }, []);

    return (
        <div>
            <div className="image-container">
                <img src="/images/Biology.jpg" alt="Bio" className="full-width-image" />
                <img src="/images/Economics.jpg" alt="Econ" className="full-width-image" />
                <img src="/images/Operations.jpg" alt="Operations" className="full-width-image" />
                <img src="/images/People.jpg" alt="People" className="full-width-image" />
            </div>
            <div className="button-container">
                <Link to="/" className="btn btn--primary-1 btn--large-1">Home</Link>
                <Link to="/allprojects" className="btn btn--primary-1 btn--large-1">All Projects</Link>
            </div>
            <h1 className='Projects'>Active Projects</h1>
            <div className="projects-container">
                {projects.map((project) => (
                    <div key={project.ProjID} className="project-item">
                        <h2>{project.Title}</h2>
                        <img src={project.Image} alt={project.Title} className="project-image" />
                        <p>{project.Bio}</p>
                        {project.Publications && project.Publications.length > 0 && (
                            <div className="publication-details-2">
                                <ul className="publication-list-2">
                                    {project.Publications.map((publication, index) => (
                                        <li key={index} className="publication-item-2">
                                            <h3>{publication.PublicationTitle}</h3>
                                            <div className="links">
                                                {publication.Pdf && (
                                                    <a href={publication.Pdf} target="_blank" rel="noopener noreferrer" className="pdf-link-2">PDF</a>
                                                )}
                                                {publication.ExtLink && (
                                                    <a href={publication.ExtLink} target="_blank" rel="noopener noreferrer" className="external-link-2">External Link</a>
                                                )}
                                                {publication.Presentation && (
                                                    <a href={publication.Presentation} target="_blank" rel="noopener noreferrer" className="presentation-link-2">Presentation</a>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {project.Status === 'Active' && (
                            <div className="project-status-label">Active</div>
                        )}
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
}
